<template>
  <exception-discount/>
</template>

<script>
import ExceptionDiscount from  '../finance/ExceptionDiscount.vue'
export default {
  components: {
    ExceptionDiscount
  }
}
</script>